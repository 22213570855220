import React, { useState } from 'react';
import './WeightCalculator.css';

export const WeightCalculator = () => {
  const [weight, setWeight] = useState(0);
  const [ounces, setOunces] = useState(0);
  const [pounds, setPounds] = useState(0);

  return (
    <div className="calc">
      <h1>Baby Weight Calculator</h1>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await setPounds(Math.floor(weight));
          console.log(weight - Math.floor(weight));
          await setOunces((weight - Math.floor(weight)) * 16);
          console.log(weight, ounces, 'submitted!');
        }}
      >
        <input
          type={'number'}
          step=".1"
          placeholder="Weight in LBS"
          onChange={(e) => {
            e.preventDefault();
            setWeight(+e.target.value);
            setOunces(
              Math.round((+e.target.value - Math.floor(+e.target.value)) * 16)
            );
            setPounds(Math.floor(+e.target.value));
          }}
        />
      </form>
      <div className="result">
        <h2>
          Baby Squish weighs <br />
          {pounds ? `${pounds} lb ${ounces} oz!` : '???'}
        </h2>
      </div>
    </div>
  );
};
