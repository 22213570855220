import { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import { Navbar } from './components/Navbar';
import { WeightCalculator } from './components/WeightCalculator';

function App() {
  const birthday = new Date('April 20, 2022');
  const today = new Date();
  const [photos, setPhotos] = useState([]);
  const days = Math.floor((today - birthday) / (1000 * 60 * 60 * 24));
  // const [message, setMessage] = useState('Loading...');
  const getPhotos = async () => {
    try {
      const res = await axios.get('/.netlify/functions/getPhotos');
      setPhotos(res.data);
      console.log('photos added!');
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPhotos();
  }, []);

  return (
    <>
      <Navbar />
      <div className="main">
        <div className="container">
          <h1>Josiah Russell Blackwell</h1>
          <h4>Age: {days} Days</h4>
          <h4>Weight: 10lb 6oz (as of 05/13/2022)</h4>
          <div className="images">
            {photos.length &&
              photos.slice(4).map((image, i) => (
                <div className="image" key={i}>
                  <img src={image} alt="" />
                </div>
              ))}
          </div>
        </div>
        <div className="container" id="calc">
          <WeightCalculator />
        </div>
      </div>
    </>
  );
}

export default App;
