import React from 'react';
import './Navbar.css';

export const Navbar = () => {
  return (
    <nav>
      <div className="logo">
        <h1>👶🏾 SQUISH</h1>
      </div>
      <ul>
        <li>
          <button>Home</button>
        </li>
        <li>
          <button>About</button>
        </li>
        <li>
          <button>Gallery</button>
        </li>
      </ul>
    </nav>
  );
};
